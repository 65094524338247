import { Route } from '@angular/router';
import { DeactivationGuarded } from '@commons/services/can-deactivate-confirmation.service';
import { archivingResolver } from '@features/partners/builder/settings/platform-access/archiving.resolver';
import { PLATFORM_ACCESS_ROUTING } from '@features/partners/builder/settings/platform-access/routes';
import { Observable } from 'rxjs';

export const PLATFORM_ACCESS_ROUTES: Route[] = [
  {
    path: PLATFORM_ACCESS_ROUTING.Archive,
    resolve: {
      archivingStatus: archivingResolver,
    },
    loadComponent: async () =>
      (await import('@features/partners/builder/settings/platform-access/archiving/archiving.component'))
        .ArchivingComponent,
  },
  {
    path: PLATFORM_ACCESS_ROUTING.CoPlatform,
    canDeactivate: [(comp: DeactivationGuarded): Observable<boolean> => comp.canDeactivate()],
    loadComponent: async () =>
      (await import('@features/partners/builder/settings/platform-access/co-platform/co-platform.component'))
        .CoPlatformComponent,
  },
  {
    path: '',
    redirectTo: PLATFORM_ACCESS_ROUTING.Archive,
    pathMatch: 'full',
  },
];
