import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@env/environment';
import { ArchivingStatus } from '@features/partners/builder/settings/platform-access/archiving/archiving.component';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ArchivingWebservice {
  readonly #http = inject(HttpClient);
  readonly baseUrl = `${environment.api.obendy}` as const;

  get(partnerId: string): Observable<ArchivingStatus> {
    return this.#http.get<ArchivingStatus>(`${this.baseUrl}/v1/partner-revision/${partnerId}/archiving-status`);
  }
}
